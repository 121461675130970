import React, { useState } from "react";
import { Input, Select } from "antd";
import CustomModel from "../../../../components/CustomModel/CustomModel";

const { Option } = Select;

function AddRoleModal({ visible, onClose, onSave }) {
  const [name, setName] = useState("");
  const [level, setLevel] = useState("Level 1");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;

    const roleLevel = level ? parseInt(level.split(" ")[1]) : 1; // Default to Level 1 if undefined

    const requestBody = {
      rolename: name,
      rolelevel: roleLevel,
      roledescription: description,
      adminid: adminId,
    };

    console.log(requestBody);

    const url = "https://usfarmdata.org/fastapi/user_function/?operation=createrole";
    const method = "POST";

    setLoading(true);

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();

      if (!response.ok) {
        console.error("Failed to save role:", responseData);
        const errorMessage = responseData.detail || "Unknown error";
        setErrorMessage(errorMessage);
        return;
      }

      setErrorMessage("");
      onSave(responseData);
      onClose();
    } catch (error) {
      console.error("Error during save operation:", error);
      setErrorMessage("Failed to save role due to a network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModel
      title="Add Role"
      open={visible}
      onSave={handleSave}
      onCancel={onClose}
      loading={loading}
      saveButtontext="Save"
    >
      <Input
        className="mb-3"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Type Role Name"
      />
      {errorMessage && (
        <div className="text-red-500 text-sm mb-2">{errorMessage}</div>
      )}
      <Select
        className="mb-3 w-full"
        value={level}
        onChange={(value) => setLevel(value)}
      >
        <Option value="Level 1">Level 1</Option>
        <Option value="Level 2">Level 2</Option>
        <Option value="Level 3">Level 3</Option>
      </Select>
      <Input
        className="mb-3"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Role Description"
      />
    </CustomModel>
  );
}

export default AddRoleModal;
