import React, { createContext, useContext, useEffect, useState } from "react";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (response) => {
    const userData = {
      accessToken: response.access_token,
      adminId: response.adminid,
      createdDate: response.createddate,
      email: response.emailid,
      firstName: response.firstname,
      lastName: response.lastname,
      lastSignInDate: response.lastsignindate,
      loginFromGoogle: response.loginfromgoogle,
      phone: response.phone,
      roleName: response.roleName,
      uniqueId: response.uniqueid,
      userId: response.userid,
    };
    setUser(userData);
    localStorage.setItem("logindata", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("logindata");
    localStorage.removeItem("token");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("logindata");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <LoginContext.Provider value={{ user, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
};

export const useLogin = () => useContext(LoginContext);
