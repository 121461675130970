import React from "react";
import { Container } from "@lib/Layout";
import { Empty } from "antd";
import InternetEmpty from "../../images/nointernent.jpg";

const NetworkErrorPage = () => {
  return (
    <Container align="both">
      <Empty
        description={
          <span style={{ fontSize: "18px" }}>
            Check your internet connection!
          </span>
        }
        image={InternetEmpty}
        imageStyle={{
          height: 300,
        }}
      />
    </Container>
  );
};

export default NetworkErrorPage;
