import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRoles } from "../../../context/RoleContext";

const AddUser = () => {
  const { roles } = useRoles();
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    role: "",
    userName: "",
    password: "",
    adminId: "",
    roleLevel: 0,
    roleid: "",
  });

  const [errors, setErrors] = useState({});

  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validatePhone = (phone) =>
    /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phone);
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password) =>
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/.test(password);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let newErrors = { ...errors };

    if (name === "firstName" || name === "lastName") {
      if (!validateName(value)) {
        newErrors[name] = "Name should contain only letters and spaces.";
      } else {
        delete newErrors[name];
      }
    }

    if (name === "phone") {
      if (!validatePhone(value)) {
        newErrors[name] = "Invalid phone number format.";
      } else {
        delete newErrors[name];
      }
    }

    if (name === "email") {
      if (!validateEmail(value)) {
        newErrors[name] = "Invalid email address.";
      } else {
        delete newErrors[name];
      }
    }
    if (name === "password") {
      if (!validatePassword(value)) {
        newErrors[name] =
          "Password must contain one digit from 1 to 9, one lowercase letter, one uppercase letter, one special character, no space, and it must be 8-16 characters long.";
      } else {
        delete newErrors[name];
      }
    }

    if (name === "rolename") {
      const selectedRole = roles.find(
        (role) => role.roleid === parseInt(value)
      );
      setUser((prevUser) => ({
        ...prevUser,
        roleid: selectedRole?.roleid || 0,
        rolename: selectedRole?.rolename || "",
      }));
    } else {
      setUser((prevUser) => ({ ...prevUser, [name]: value }));
    }

    setErrors(newErrors);
  };

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;

    if (Object.keys(errors).length === 0) {
      try {
        const response = await fetch(
          "https://usfarmdata.org/fastapi/user_function/?operation=adduser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              firstname: user.firstName,
              lastname: user.lastName,
              emailid: user.email,
              phone: user.phone,
              loginname: user.userName,
              password: user.password,
              adminid: adminId,
              rolelevel: user.roleLevel,
              roleid: user.roleid,
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          console.log("Added Data:", result);
          console.log("User added successfully", response);
          alert("User added successfully");
          navigate("/listuser");
        } else {
          console.error("Failed to add user:", response.statusText);
        }
      } catch (error) {
        console.error("Error adding user:", error);
      }
    } else {
      alert("Please fix the errors before submitting.");
      console.error("Please fix the errors before submitting.");
    }
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/listuser");
  };

  return (
    <div className="flex l flex-col bg-gray-100 p-8">
      <div className="flex flex-col justify-center items-center">
        <div style={{ minWidth: "80%", maxWidth: "85%" }}>
          <div className="flex mb-4 justify-between">
            <h2 className="text-xl font-bold text-blue-500 grow">Add User</h2>

            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md"
              onClick={handleSave}
            >
              Save
            </button>
          </div>

          <div>
            <div className="bg-white shadow-md rounded-lg p-8">
              <div className="mb-6 grid-cols-1 divide-y">
                <h3 className="text-lg font-bold mb-2">User Information</h3>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-gray-700">First Name</label>
                    <input
                      type="text"
                      name="firstName"
                      className="w-full p-2 border rounded-md"
                      value={user.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.firstName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700">Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="w-full p-2 border rounded-md"
                      value={user.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.lastName}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      className="w-full p-2 border rounded-md"
                      value={user.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.phone}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="w-full p-2 border rounded-md"
                      value={user.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.email}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="block text-gray-700">Role</label>
                    <select
                      name="rolename"
                      className="w-full p-2 border rounded-md"
                      value={user.roleid}
                      onChange={handleChange}
                    >
                      <option value=""></option>
                      {roles.map((role) => (
                        <option key={role.roleid} value={role.roleid}>
                          {role.rolename}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">User Name</label>
                    <input
                      type="text"
                      name="userName"
                      className="w-full p-2 border rounded-md"
                      value={user.userName}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label className="block text-gray-700">Password</label>
                    <input
                      type="password"
                      className="w-full p-2 border rounded-md"
                      name="password"
                      value={user.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-xs mt-1">
                        {errors.password}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
