import React, { createContext, useState, useEffect } from "react";

const WindowContext = createContext();

export const WindowWidthProvider = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowContext.Provider value={windowWidth}>
      {children}
    </WindowContext.Provider>
  );
};

export const useWindowWidth = () => React.useContext(WindowContext);
