import React from 'react';

const HeaderField = ({ label, discription}) => {
    return (
        <div className='flex flex-col gap-2'>
            <label className='text-sm text-zinc-400 font-segoe  font-normal'>{label}</label>
            <div className='font-roboto font-normal text-sm'>{discription}</div>
        </div>
    );
};


export default HeaderField;
