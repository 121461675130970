import React, { useState, useEffect } from "react";
import { Form, Input, Select, message } from "antd";
import CustomModel from "../CustomModel/CustomModel";

const { Option } = Select;

const SentInviteModal = ({ visible, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  const fetchRoles = async () => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;
    const userId = loginData ? loginData.userId : null;
    const Token = loginData ? loginData.accessToken : null;

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/user_function/?operation=getrole",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({
            userid: userId,
            adminid: adminId,
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const formattedRoles = result.map((role) => ({
          label: role.rolename,
          value: role.roleid,
        }));
        setRoles(formattedRoles);
      } else {
        message.error("Failed to fetch roles.");
      }
    } catch (error) {
      message.error("Error fetching roles.");
    }
  };

  const handleRoleChange = (value) => {
    console.log("Selected roleid:", value);
  };

  const handleSendInvite = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const selectedRoleId = values.role;

      setLoading(true);

      const savedLoginData = JSON.parse(localStorage.getItem("logindata"));
      const token = savedLoginData?.accessToken;
      const adminId = savedLoginData?.adminId;

      const response = await fetch(
        "https://usfarmdata.org/fastapi/user_function/?operation=send_invite",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            emailid: values.email,
            adminid: adminId,
            roleid: selectedRoleId,
          }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        if (response.status === 409) {
          throw new Error(result.detail);
        }
        throw new Error(result.detail || "Failed to send invite.");
      }

      message.success("Invite sent successfully!");
      form.resetFields();
      onClose();
    } catch (error) {
      message.error(
        error.message || "An error occurred while sending the invite."
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchRoles();
    }
  }, [visible]);

  return (
    <CustomModel
      title="Send Invite"
      open={visible}
      onSave={handleSendInvite}
      onCancel={onClose}
      loading={loading}
      width={600}
      saveButtontext="Send Invite"
    >
      <div
        style={{
          boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        }}
        className="p-3 mt-5 mb-5 rounded"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter the email address.",
              },
              {
                type: "email",
                message: "Please enter a valid email address.",
              },
            ]}
            style={{ width: "50%" }}
          >
            <Input placeholder="example@company.com" />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please select a role.",
              },
            ]}
            style={{ width: "50%" }}
          >
            <Select placeholder="Select a role" onChange={handleRoleChange}>
              {roles.map((role) => (
                <Option key={role.value} value={role.value}>
                  {role.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </CustomModel>
  );
};

export default SentInviteModal;
