import React, { useRef, useState, useEffect } from "react";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import EditIcon from "@mui/icons-material/Edit";
import { Button, DatePicker, Input, Space, Table, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { AutoSizer } from "react-virtualized";
import styled from "@emotion/styled";
import { calculateTableHeight } from "./calculateTableHeight";

const Edit = styled(EditIcon)`
  font-size: 1rem;
  cursor: pointer;
`;

const CustomTable = ({
  columns,
  data,
  onEdit,
  size = "middle",
  onDelete,
  actionColumnProps,
  onRowClick,
  rowKey,
  needActionColumn = true,
  selectableRows = true,
  onSelectChange,
  xScroll = 1000,
  EmptyMessage,
  getCheckboxProps = () => ({ disabled: false }),
  heightOffset = 350,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const numericHeightOffset = Number(heightOffset);

  const [tableHeight, setTableHeight] = useState(
    calculateTableHeight(numericHeightOffset)
  );

  const handleResize = () => {
    setTableHeight(calculateTableHeight(numericHeightOffset));
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [numericHeightOffset]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const isDateColumn = (dataIndex) => {
    return columns.find(
      (col) => col.dataIndex === dataIndex && col.type === "date"
    );
  };

  const getColumnSearchProps = (dataIndex, needSearch) => {
    if (!needSearch) {
      return {};
    }

    const isDate = isDateColumn(dataIndex);

    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        close,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          {isDate ? (
            <DatePicker
              format="YYYY-MM-DD"
              value={selectedKeys[0] ? moment(selectedKeys[0]) : null}
              onChange={(date, dateString) =>
                setSelectedKeys(dateString ? [dateString] : [])
              }
              placeholder={`Search ${dataIndex}`}
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />
          ) : (
            <Input
              ref={searchInput}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={{
                marginBottom: 8,
                display: "block",
              }}
            />
          )}
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters && handleReset(clearFilters)}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button type="link" size="small" onClick={() => close()}>
              Close
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? "#1677ff" : undefined,
          }}
        />
      ),
      onFilter: (value, record) => {
        if (!value) return true;
        if (isDate) {
          const dateValue = moment(record[dataIndex]).format("YYYY-MM-DD");
          return dateValue.includes(value);
        }
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{
              backgroundColor: "#ffc069",
              padding: 0,
            }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    };
  };

  const getSorter = (a, b, dataIndex) => {
    if (typeof a[dataIndex] === "number" && typeof b[dataIndex] === "number") {
      return a[dataIndex] - b[dataIndex];
    }
    return a[dataIndex].localeCompare(b[dataIndex]);
  };

  const actionColumn = {
    title: "Actions",
    key: "actions",
    fixed: "right",
    width: 100,
    render: (text, record) => {
      const checkboxProps = getCheckboxProps(record);
      return !checkboxProps.disabled ? (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              icon={<Edit />}
              onClick={() => onEdit(record)}
              type="primary"
              danger
              // shape="circle"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              icon={<DeleteOutlined />}
              onClick={() => onDelete(record)}
              type="primary"
              danger
              // shape="circle"
            />
          </Tooltip>
        </Space>
      ) : null;
    },
    ...actionColumnProps,
  };

  const enhancedColumns = [
    ...columns.map((col) => ({
      ...col,
      ...getColumnSearchProps(col.dataIndex, col.needSearch),
      sorter: col.needSorting
        ? (a, b) => getSorter(a, b, col.dataIndex)
        : false,
      sortDirections: col.needSorting ? ["ascend", "descend"] : undefined,
    })),
    ...(needActionColumn ? [actionColumn] : []),
  ];

  const handleRowClick = (record) => {
    if (onRowClick) {
      onRowClick(record, record[rowKey]);
    }
  };

  // const handleRowClassName = (record) => {
  //   const checkboxProps = getCheckboxProps(record);
  //   return checkboxProps.disabled ? "text-gray-300 cursor-not-allowed" : "";
  // };

  const rowProps = (record) => {
    return {
      onClick: () => handleRowClick(record),
      // className: handleRowClassName(record),
    };
  };

  const rowSelection = selectableRows
    ? {
        fixed: true,
        columnWidth: 50,
        onChange: (selectedRowKeys, selectedRows) => {
          if (onSelectChange) {
            onSelectChange(selectedRowKeys, selectedRows);
          }
        },
        getCheckboxProps,
      }
    : null;

  return (
    <AutoSizer disableWidth style={{ width: "auto", height: tableHeight }}>
      {({ height }) => (
        <Table
          onRow={rowProps}
          rowKey={rowKey}
          virtual
          rowSelection={rowSelection}
          columns={enhancedColumns}
          dataSource={data}
          scroll={{ y: height, x: xScroll }}
          pagination={false}
          size={size}
          locale={EmptyMessage}
        />
      )}
    </AutoSizer>
  );
};

export default CustomTable;
