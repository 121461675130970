import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, Modal } from "antd";
import CustomTable from "@components/CustomTable/CustomTable";
import SentInviteModal from "@components/SentInviteModal/SentInviteModal";
import Loading from "@components/Loading/Loading";
import EmptyLogo from "../../../images/empty-new.jpg";

const { confirm } = Modal;

const columns = [
  {
    title: "User ID",
    dataIndex: "userid",
    key: "userid",
    width: "10%",
  },
  {
    title: "Name",
    dataIndex: "firstname",
    key: "name",
    width: "20%",
    render: (text, record) => `${record.firstname} ${record.lastname}`,
  },
  {
    title: "Email",
    dataIndex: "emailid",
    key: "email",
    width: "20%",
    sorter: (a, b) => a.emailid.length - b.emailid.length,
  },
  {
    title: "User Role",
    dataIndex: "rolename",
    key: "role",
    width: "20%",
  },
];

const TeamMembers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const adminId = loginData ? loginData.adminId : null;
      const Token = loginData ? loginData.accessToken : null;

      try {
        const response = await fetch(
          "https://usfarmdata.org/fastapi/user_function/?operation=getuser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
            body: JSON.stringify({
              adminid: adminId,
            }),
          }
        );

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage =
            errorResponse.detail || "An unknown error occurred";
          throw new Error(errorMessage);
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderEmptyState = () => {
    return (
      <div className="flex h-full items-center justify-center">
        <Empty
          description={error || "No team members found."}
          image={EmptyLogo}
          imageStyle={{
            height: 250,
          }}
        />
      </div>
    );
  };

  return (
    <Container className="gap 2" alignBox="column">
      {loading ? (
        <div className="h-full">
          <Loading />
        </div>
      ) : (
        <>
          <Box className="border-b py-2 px-4">
            <div className="flex items-center">
              <div className="grow text-lg font-semibold">Team Members</div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleOpenModal}
                >
                  Add Member
                </Button>
              </div>
            </div>
          </Box>
          <Box className="p-2">
            <CustomTable
              columns={columns}
              selectableRows={false}
              data={data}
              xScroll={500}
              needActionColumn={false}
              heightOffset={300}
              EmptyMessage={{ emptyText: renderEmptyState() }}
            />
          </Box>
        </>
      )}

      <SentInviteModal visible={isModalOpen} onClose={handleCloseModal} />
    </Container>
  );
};

export default TeamMembers;
