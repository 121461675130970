import React, { useState, useRef } from "react";
import axios from "axios";
import { message, Button } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import EditLogo from "../../../images/EditDocument.png";
import VerifyLogo from "../../../images/DragAndDrop.png";
import Done from "../../../images/Done.png";
import { useLogin } from "../../../context/LoginContext";
import PortalLogo from "../../../images/PortalLogo.png";

const Password = ({ isForgotPassword = false }) => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [loading, setLoading] = useState(false);
  const [reloading, setReLoading] = useState(false);
  const inputRefs = useRef([]);
  const { logout } = useLogin();

  // Step 1: Send Email for OTP
  const handleSendEmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    localStorage.setItem("resetemailid", email);
    try {
      const response = await axios.post(
        "https://usfarmdata.org/fastapi/login_function/?operation=forgetpassword",
        { email }
      );
      message.success(response.data.message);
      setStep(2); // Move to OTP verification
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to send email.");
    } finally {
      setLoading(false);
    }
  };

  // Step 2: OTP Verification
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    if (/^\d*$/.test(value)) {
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyAccount = async () => {
    const otpCode = otp.join("");
    setLoading(true);
    try {
      const response = await axios.post(
        "https://usfarmdata.org/fastapi/login_function/?operation=validateotp",
        {
          otp: Number(otpCode),
          email,
        }
      );
      message.success(response.data.message);
      setStep(3);
    } catch (error) {
      message.error(
        error.response?.data?.message || "OTP verification failed."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setReLoading(true);
    const resetEmail = localStorage.getItem("resetemailid");
    try {
      const response = await axios.post(
        "https://usfarmdata.org/fastapi/login_function/?operation=forgetpassword",
        { email: resetEmail }
      );
      message.success(response.data.message);
    } catch (error) {
      message.error(error.response?.data?.message || "Failed to send OTP.");
    } finally {
      setReLoading(false);
    }
  };

  // Step 3: Change Password
  const getPasswordStrength = (password) => {
    if (password.length < 8) {
      return "Too short";
    } else if (password.match(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/)) {
      return "Strong";
    } else if (password.match(/^(?=.*[A-Za-z])(?=.*\d)/)) {
      return "Medium";
    }
    return "Weak";
  };

  const handleNewPasswordChange = (e) => {
    const value = e.target.value;
    setNewPassword(value);
    setPasswordStrength(getPasswordStrength(value));
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      message.error("Password must be at least 8 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        "https://usfarmdata.org/fastapi/login_function/?operation=resetpassword",
        { password: newPassword, email }
      );
      message.success(response.data.message);
      setStep(4); // Move to success step
    } catch (error) {
      message.error(error.response?.data?.message || "Password reset failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    message.info("Logging out...");
    logout(); // Call the context's logout function to clear user data
    window.location.href = "/login"; // Redirect to login page after logging out
  };

  const handleStayLoggedIn = () => {
    message.success("You will stay logged in.");
  };

  return (
    <div className="flex items-center justify-center h-full bg-white rounded-md">
      <div
        className="bg-white p-8 rounded-lg  w-96"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        {isForgotPassword && (
          <img
            src={PortalLogo}
            alt="Logo"
            className="mx-auto  w-[204px] h-[64px]"
          />
        )}

        {step === 1 && (
          <>
            {/* Forgot Password */}
            <div className="flex items-center justify-center">
              <img src={EditLogo} alt="Verify Email" className="w-36 h-full" />
            </div>
            <h2 className="text-center text-xl font-semibold mb-1">
              Reset Password
            </h2>
            <p className="text-center text-xs mb-4 text-slate-400">
              We will send you a{" "}
              <i className="font-semibold">One Time Password</i> on your Email
              address
            </p>
            <form onSubmit={handleSendEmail}>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Enter your email address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <Button
                type="primary"
                loading={loading}
                className="w-full"
                htmlType="submit"
              >
                GET OTP
              </Button>
            </form>
          </>
        )}

        {step === 2 && (
          <>
            {/* OTP Verification */}
            <div className="flex items-center justify-center">
              <img src={VerifyLogo} alt="Verify OTP" className="w-36 h-full" />
            </div>
            <h2 className="text-center text-xl font-bold mb-4">
              Enter the Verification Code
            </h2>
            <p className="text-center text-xs mb-6 text-gray-600">
              You will get an OTP via <i className="font-semibold">Email</i>
            </p>
            <div className="flex justify-center mb-6">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="w-12 h-12 mx-1 border rounded text-center text-xl"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>
            <Button
              type="primary"
              loading={loading}
              className="w-full"
              onClick={handleVerifyAccount}
            >
              VERIFY
            </Button>
            <p className="text-center mt-4 text-gray-600 flex items-center gap-2">
              <span>Didn’t receive code?</span>
              <button
                className="text-blue-500 text-sm hover:underline"
                onClick={handleResendOtp}
              >
                Resend OTP
              </button>
              {reloading && (
                <span>
                  <CircularProgress color="secondary" size={15} />
                </span>
              )}
            </p>
          </>
        )}

        {step === 3 && (
          <>
            {/* Change Password */}
            <div className="flex items-center justify-center">
              <img src={Done} alt="Change Password" className="w-36 h-full" />
            </div>
            <h2 className="text-center text-xl font-bold mb-4">
              Update Your New Password
            </h2>
            <form onSubmit={handleChangePassword}>
              <div className="mb-4">
                <label
                  htmlFor="new-password"
                  className="block text-gray-700 font-bold mb-2"
                >
                  New Password
                </label>
                <input
                  type="password"
                  id="new-password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  required
                />
                <p className="text-sm mt-2">
                  Strength:{" "}
                  <span
                    className={
                      passwordStrength === "Strong"
                        ? "text-green-500"
                        : passwordStrength === "Medium"
                        ? "text-yellow-500"
                        : passwordStrength === "Weak"
                        ? "text-red-500"
                        : "text-gray-500"
                    }
                  >
                    {passwordStrength}
                  </span>
                </p>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirm-password"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirm-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <Button
                type="primary"
                loading={loading}
                className="w-full"
                htmlType="submit"
              >
                UPDATE PASSWORD
              </Button>
            </form>
          </>
        )}

        {step === 4 && (
          <>
            {/* Success */}
            <div className="flex items-center justify-center">
              <img src={Done} alt="Success" className="w-36 h-full" />
            </div>
            <h2 className="text-center text-xl font-bold mb-4">
              Password Updated Successfully!
            </h2>
            <p className="text-center text-gray-600">
              Your password has been updated. You can now log in with your new
              password.
            </p>

            {isForgotPassword && (
              <div className="flex justify-center items-center">
                <a
                  href="/login"
                  className="p-2 mt-4 text-center w-28 inline-block rounded-md bg-blue-500 text-white"
                >
                  login
                </a>
              </div>
            )}

            {!isForgotPassword && (
              <div className="flex flex-col items-center mt-6">
                <p className="text-center text-gray-600 mb-4">
                  Do you want to log out or stay logged in?
                </p>
                <div className="flex gap-4">
                  <Button type="primary" onClick={handleLogout}>
                    Log out
                  </Button>
                  <Button type="default" onClick={handleStayLoggedIn}>
                    Stay Logged In
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Password;
