import { useState, useEffect } from "react";

const useFetchListData = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const logindata = JSON.parse(localStorage.getItem("logindata"));
        const apiToken = logindata?.accessToken;
        const currentList = JSON.parse(localStorage.getItem("currentlist"));
        const listid = currentList?.listid;

        if (!apiToken || !listid) {
          throw new Error("Missing API token or listid");
        }

        const response = await fetch(
          "https://usfarmdata.org/fastapi/contact_function/?operation=getbylistid",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${apiToken}`,
            },
            body: JSON.stringify({
              listid: listid,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch items: ${response.statusText}`);
        }

        const result = await response.json();
        setData(result);
      } catch (err) {
        setError(err.detail);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export default useFetchListData;
