import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";

const TextBoxField = ({
  value,
  onChange,
  placeholder,
  name,
  label,
  allowEdit = false,
  type = "text",
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleIconClick = () => {
    if (allowEdit) {
      setIsEditing(true);
    }
  };

  return (
    <div className="relative z-0 min-w-48 mb-5 group">
      <label
        htmlFor={name}
        className="block text-sm text-gray-500 dark:text-gray-400"
      >
        {label}
      </label>
      <div className="relative">
        <input
          type={type}
          name={name}
          id={name}
          className="block py-2 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          readOnly={!isEditing && allowEdit}
          autoFocus={isEditing}
        />
        {allowEdit && (
          <button
            type="button"
            className="absolute bottom-0 right-0 mb-1 mr-1 text-gray-500 opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={handleIconClick}
          >
            <Tooltip title="Edit">
              <svg
                className="text-slate-500"
                width="17"
                height="17"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
              </svg>
            </Tooltip>
          </button>
        )}
      </div>
    </div>
  );
};

export default TextBoxField;
