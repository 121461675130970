import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import Tooltip from "@mui/material/Tooltip";

const FieldContainer = ({
  title,
  children,
  draggable = false,
  onDragEnter,
  onDragOver,
  onDragStart,
}) => {
  const [isOpen, setIsOpen] = useState(() => {
    const storedState = localStorage.getItem(`fieldContainer_${title}`);
    return storedState ? JSON.parse(storedState) : true;
  });

  useEffect(() => {
    localStorage.setItem(`fieldContainer_${title}`, JSON.stringify(isOpen));
  }, [title, isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container
      alignBox="column"
      className={`min-w-96 rounded-md shadow-md transition-shadow duration-300 ${
        isOpen ? "shadow-md" : ""
      }`}
      style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;" }}
      isCover={false}
    >
      <Box
        className="border-b border-gray-300 px-4 py-2 bg-gray-200 cursor-pointer rounded-t-lg"
        onClick={toggleAccordion}
      >
        <Container
          alignBox="row"
          align="vertical"
          className="justify-between"
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          draggable={draggable}
        >
          <Box
            className="font-segoe  text-lg font-semibold antialiased"
            style={{ color: "#525254" }}
          >
            {title}
          </Box>
          <Box
            className={`transform transition-transform duration-300 ${
              isOpen ? "rotate-180" : "rotate-0"
            }`}
          >
            <Tooltip title={isOpen ? "collapse" : "Expand"}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Tooltip>
          </Box>
        </Container>
      </Box>
      <Box
        className={`overflow-hidden transition-all duration-300  ${
          isOpen ? "max-h-full opacity-100 p-4" : "max-h-0 opacity-0"
        }`}
        style={{ maxHeight: isOpen ? "1000px" : "0" }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default FieldContainer;
