


import React from "react";
import ListUser from "../ListUser/ListUser";
// import Test from "../ListUser/Test";


const UserManagementHome =()=>{

  return (
    <ListUser></ListUser>
    //<RolePage></RolePage>
    //<Test></Test>
  );


}

export default UserManagementHome;
