import React, { createContext, useContext, useState, useEffect } from "react";

const FontSizeContext = createContext();

export const useFontSize = () => useContext(FontSizeContext);

export const FontSizeProvider = ({ children }) => {
  const rootFontSize = 16; // 1rem is equal to 16px by default
  const minFontSize = 1; // Minimum font size in rem (16px)
  const maxFontSize = 1.25; // Maximum font size in rem (20px)
  const [fontSize, setFontSize] = useState(minFontSize); // Default font size in rem (1rem = 16px)

  useEffect(() => {
    document.documentElement.style.fontSize = `${fontSize}rem`;
  }, [fontSize]);

  const increaseFontSize = () => {
    setFontSize((prevSize) =>
      Math.min(prevSize + 1 / rootFontSize, maxFontSize)
    );
  };

  const decreaseFontSize = () => {
    setFontSize((prevSize) =>
      Math.max(prevSize - 1 / rootFontSize, minFontSize)
    );
  };

  const resetFontSize = () => setFontSize(minFontSize); // Reset to 1rem (16px)

  const isDefaultSize = fontSize === minFontSize;

  return (
    <FontSizeContext.Provider
      value={{
        fontSize,
        increaseFontSize,
        decreaseFontSize,
        resetFontSize,
        isDefaultSize,
      }}
    >
      {children}
    </FontSizeContext.Provider>
  );
};
