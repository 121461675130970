import React from "react";
import { Container, Box } from "@lib/Layout";
const Header = (props) => {
  const { renderLoginDetails, renderLogo } = props;
  return (
    <Container
      alignBox="row"
      isCover={false}
      className="px-3 py-2 h-14 bg-white"
      tagName="header"
      align="vertical"
    >
      <Box>{renderLogo()}</Box>
      <Box flexible>{renderLoginDetails()}</Box>
    </Container>
  );
};

export default Header;
