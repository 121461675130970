import React from "react";

const SkeletonLoader = () => {
  return (
    <div className="space-y-4">
      {Array.from({ length: 10 }).map((_, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-4 border border-gray-200 rounded shadow animate-pulse"
        >
          <div className="flex flex-col space-y-2">
            <div className="w-24 h-2 bg-gray-300 rounded"></div>
            <div className="w-32 h-2 bg-gray-200 rounded"></div>
          </div>
          <div className="w-12 h-2 bg-gray-300 rounded"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
