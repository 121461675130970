import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate, NavLink, Outlet, useLocation } from "react-router-dom";
import Header from "@lib/Header/Header";
import { useLogin } from "../../context/LoginContext";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";
import Logo from "../../images/PortalLogo.png";
import useIsAdmin from "../../hooks/useIsAdmin";
import FontSizeDropdown from "../../components/FontSizeDropdown/FontSizeDropdown";

const Home = () => {
  const { user, login, logout } = useLogin();
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const savedState = localStorage.getItem("isMenuOpen");
    try {
      return savedState === null ? false : JSON.parse(savedState);
    } catch (error) {
      return false;
    }
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [profile, setProfile] = useState(() => {
    const savedProfile = localStorage.getItem("profile");
    return savedProfile ? JSON.parse(savedProfile) : null;
  });
  const [logindata, setLoginData] = useState(() => {
    const savedLoginData = localStorage.getItem("logindata");
    return savedLoginData ? JSON.parse(savedLoginData) : null;
  });

  useEffect(() => {
    localStorage.setItem("isMenuOpen", JSON.stringify(isMenuOpen));
  }, [isMenuOpen]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLoginClick = () => {
    login();
    navigate("/login");
  };

  const handleLogoutClick = () => {
    localStorage.removeItem("logindata");
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    localStorage.removeItem("currentlist");
    localStorage.removeItem("contactId");
    localStorage.removeItem("view");
    window.location.reload();
    logout();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderLoginDetails = () => {
    const userData = profile || logindata;

    if (!userData) return null;

    const {
      name = `${logindata?.firstName || ""} ${logindata?.lastName || ""}`,
      email = logindata?.email,
      roleName = logindata?.roleName || "User",
      picture = profile?.picture || "",
    } = userData;

    const getInitials = (name) => {
      const nameParts = name.split(" ");
      if (nameParts.length > 1) {
        return nameParts[0][0] + nameParts[1][0];
      }
      return nameParts[0][0] + (nameParts[0][1] || "");
    };

    const initials = getInitials(name);

    return (
      <div className="relative flex justify-end items-center z-20 gap-5">
        <div>
          <FontSizeDropdown />
        </div>
        <button
          id="dropdownAvatarNameButton"
          data-dropdown-toggle="dropdownAvatarName"
          className="flex gap-1 items-center text-sm pe-1 font-medium text-gray-900 rounded-full md:me-0 focus:ring-4 focus:ring-blue-200 hover:ring-4 hover:ring-blue-100"
          type="button"
          onClick={toggleDropdown}
        >
          <span className="sr-only">Open user menu</span>
          {picture ? (
            <img
              className="w-8 h-8 me-2 rounded-full ring-2 ring-gray-300"
              src={picture}
              alt="user photo"
            />
          ) : (
            <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {initials}
              </span>
            </div>
          )}
          {name}
          <svg
            className={`w-2.5 h-2.5 ms-3 transition-transform ${
              isDropdownOpen ? "rotate-180" : "rotate-0"
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        {isDropdownOpen && (
          <div
            id="dropdownAvatarName"
            className="absolute top-9 border right-0 mt-2 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44"
          >
            <div className="px-4 py-3 text-sm text-gray-900">
              <div className="font-medium">{name}</div>
              {/* <div className="truncate">{email}</div> */}
              <div className="truncate text-green-600 text-xs">
                <span>Role: </span>
                <span>{roleName}</span>
              </div>
            </div>
            <ul
              className="py-2 text-sm text-gray-700 "
              aria-labelledby="dropdownAvatarNameButton"
            >
              <li>
                <a
                  href="/dashboard"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <a
                  href="/settings"
                  className="block px-4 py-2 hover:bg-gray-100"
                >
                  Settings
                </a>
              </li>
            </ul>
            <div className="py-2">
              <a
                href="#"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={handleLogoutClick}
              >
                Sign out
              </a>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <Container alignBox="row" className="gap-5">
        <div className="flex justify-center items-center" onClick={toggleMenu}>
          {windowWidth > 600 && (
            <div
              className={`flex justify-center items-center rounded-md p-2 cursor-pointer ${
                isMenuOpen ? "bg-slate-200" : "hover:bg-slate-200"
              }`}
            >
              {isMenuOpen ? (
                <Tooltip title="Close">
                  <CloseOutlinedIcon />{" "}
                </Tooltip>
              ) : (
                <Tooltip title="Expand">
                  <MenuOpenOutlinedIcon />
                </Tooltip>
              )}
            </div>
          )}
        </div>

        <span className="inline-block">
          <img src={Logo} alt="goleads" loading="lazy" className="w-1/4" />
        </span>
      </Container>
    );
  };

  const Lhs = () => {
    const isAdmin = useIsAdmin();
    const items = [
      {
        label: "Home",
        url: "/dashboard",
        icon: <HomeIcon />,
        isActive:
          location.pathname === "/" ||
          location.pathname.startsWith("/dashboard"),
      },
      isAdmin && {
        label: "User Management",
        url: "/usermanagement",
        icon: <AccountCircleIcon />,
      },
      // {
      //   label: "Contacts",
      //   url: "/contacts",
      //   icon: <GroupIcon />,
      // },
      {
        label: "Settings",
        url: "/settings",
        icon: <SettingsIcon />,
        isActive: location.pathname.startsWith("/settings"),
      },
      {
        label: "Filter",
        url: "/gmail-activies",
        icon: <EmailIcon />,
      },
    ].filter(Boolean);

    return (
      <Container
        scroll="vertical"
        isCover={false}
        alignBox="column"
        className={`transition-all duration-400 ease-in-out h-full ${
          isMenuOpen ? "w-56" : "w-16"
        }`}
      >
        <Box flexible scroll="vertical" className="p-2">
          {isMenuOpen ? (
            <ul className="flex flex-col gap-2">
              {items.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.url}
                    end
                    className={({ isActive }) =>
                      isActive || item.isActive
                        ? "font-roboto font-normal bg-blue-500 text-white p-2 block rounded-md text-sm"
                        : "text-slate-900 p-2 block text-sm rounded-md bg-slate-300 font-roboto font-normal hover:bg-blue-500 hover:text-white"
                    }
                  >
                    <div className="flex gap-1 items-center">
                      <div>{item.icon}</div>
                      <div>{item.label}</div>
                    </div>
                  </NavLink>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="flex flex-col gap-2 justify-center items-center">
              {items.map((item, index) => (
                <li key={index}>
                  <NavLink
                    to={item.url}
                    end
                    className={({ isActive }) =>
                      isActive || item.isActive
                        ? "font-roboto font-normal bg-blue-500 text-white p-2 block rounded-md text-sm"
                        : "text-slate-900 p-2 block text-sm bg-slate-300 rounded-md font-roboto font-normal hover:bg-blue-500 hover:text-white"
                    }
                  >
                    <Tooltip title={item.label}>{item.icon}</Tooltip>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </Box>
      </Container>
    );
  };

  return (
    <Container
      alignBox="column"
      tagName="section"
      className="divide-y divide-slate-200 border-slate-200 bg-slate-200"
    >
      <Box>
        <Header
          renderLoginDetails={renderLoginDetails}
          renderLogo={renderLogo}
        ></Header>
      </Box>
      <Box flexible className="bg-slate-200">
        <Container alignBox="row" className="flex flex-col md:flex-row">
          {windowWidth > 600 && (
            <Box className="bg-gray-100 border-r border-slate-200 transition duration-400 ease-in-out">
              <Lhs />
            </Box>
          )}
          <Box flexible className="bg-white">
            <Outlet />
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default Home;
