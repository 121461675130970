import { useState, useEffect } from "react";
import axios from "axios";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

const useFetchGmailEmails = (
  accessToken,
  labelId,
  maxResults = 10,
  dateFilter,
  searchQuery = ""
) => {
  const [emails, setEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [noData, setNoData] = useState(false); // To track if no emails are found

  useEffect(() => {
    const fetchEmails = async () => {
      if (!accessToken) return;

      setLoading(true);
      setError(null);
      setNoData(false); // Reset noData state

      try {
        let query = `label:${labelId}`;
        const today = new Date().toISOString().split("T")[0];
        if (dateFilter === "today") {
          query += ` after:${today}`;
        } else if (dateFilter === "last7days") {
          const pastDate = new Date();
          pastDate.setDate(pastDate.getDate() - 7);
          query += ` after:${pastDate.toISOString().split("T")[0]}`;
        } else if (dateFilter === "lastMonth") {
          const pastDate = new Date();
          pastDate.setMonth(pastDate.getMonth() - 1);
          query += ` after:${pastDate.toISOString().split("T")[0]}`;
        }

        if (searchQuery) {
          query += ` ${searchQuery}`;
        }

        const response = await axios.get(
          "https://www.googleapis.com/gmail/v1/users/me/messages",
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: "application/json",
            },
            params: {
              q: query,
              maxResults,
            },
          }
        );

        const messages = response.data.messages || [];

        if (messages.length === 0) {
          setNoData(true); // Set noData to true if no messages
          setEmails([]);
          return;
        }

        const fetchedEmails = await Promise.all(
          messages.map(async (message) => {
            const messageDetails = await axios.get(
              `https://www.googleapis.com/gmail/v1/users/me/messages/${message.id}`,
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  Accept: "application/json",
                },
              }
            );

            const headers = messageDetails.data.payload.headers;
            const subjectHeader = headers.find(
              (header) => header.name === "Subject"
            );
            const dateHeader = headers.find((header) => header.name === "Date");
            const fromHeader = headers.find((header) => header.name === "From");
            const toHeader = headers.find((header) => header.name === "To");

            const subject = subjectHeader ? subjectHeader.value : "No subject";
            const date = dateHeader ? formatDate(dateHeader.value) : "No date";
            const from = fromHeader ? fromHeader.value : "No sender";
            const to = toHeader ? toHeader.value : "No recipient";

            let messageBody = "";
            if (messageDetails.data.payload.parts) {
              const bodyPart = messageDetails.data.payload.parts.find(
                (part) =>
                  part.mimeType === "text/plain" ||
                  part.mimeType === "text/html"
              );
              if (bodyPart) {
                messageBody = bodyPart.body.data
                  ? atob(
                      bodyPart.body.data.replace(/-/g, "+").replace(/_/g, "/")
                    )
                  : "";
              }
            } else {
              messageBody = messageDetails.data.payload.body.data
                ? atob(
                    messageDetails.data.payload.body.data
                      .replace(/-/g, "+")
                      .replace(/_/g, "/")
                  )
                : "";
            }

            return {
              subject,
              date,
              from,
              to,
              messageBody,
            };
          })
        );

        setEmails(fetchedEmails);
      } catch (err) {
        console.error("Error fetching emails:", err);
        setError(err.message || "Error fetching emails.");
      } finally {
        setLoading(false);
      }
    };

    fetchEmails();
  }, [accessToken, labelId, maxResults, dateFilter, searchQuery]);

  return { emails, loading, error, noData };
};

export default useFetchGmailEmails;
