import { useRef, useEffect } from "react";

const useConactBoxDragAndDrop = (list, setList) => {
  const draggingItem = useRef(null);
  const dragOverItem = useRef(null);
  const containerRef = useRef(null);

  const handleDragStart = (e, position) => {
    draggingItem.current = position;
    e.dataTransfer.effectAllowed = "move"; // Optional: Set the drag effect
  };

  const handleDragEnter = (e, position) => {
    if (position !== draggingItem.current) {
      dragOverItem.current = position;
      const listCopy = [...list];
      const draggingItemContent = listCopy[draggingItem.current];

      listCopy.splice(draggingItem.current, 1);
      listCopy.splice(dragOverItem.current, 0, draggingItemContent);

      draggingItem.current = dragOverItem.current;
      dragOverItem.current = null;
      setList(listCopy);
      const listOrder = listCopy.map((item) => item.id);
      localStorage.setItem("draggedListOrder", JSON.stringify(listOrder));
    }
  };

  const handleDragEnd = () => {
    draggingItem.current = null;
    dragOverItem.current = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    if (containerRef.current) {
      const { top, bottom, left, right } =
        containerRef.current.getBoundingClientRect();
      const scrollSpeed = 10; // Increased scroll speed for better UX

      if (e.clientY < top + 20) {
        containerRef.current.scrollBy(0, -scrollSpeed);
      } else if (e.clientY > bottom - 20) {
        containerRef.current.scrollBy(0, scrollSpeed);
      }
      if (e.clientX < left + 20) {
        containerRef.current.scrollBy(-scrollSpeed, 0);
      } else if (e.clientX > right - 20) {
        containerRef.current.scrollBy(scrollSpeed, 0);
      }
    }
  };

  useEffect(() => {
    const handleDragEndGlobal = () => handleDragEnd();
    window.addEventListener("dragend", handleDragEndGlobal);

    return () => {
      window.removeEventListener("dragend", handleDragEndGlobal);
    };
  }, []);

  return {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleDragOver,
    containerRef,
  };
};

export default useConactBoxDragAndDrop;
