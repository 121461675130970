import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomTable from "@components/CustomTable/CustomTable";
import { Container, Box } from "@lib/Layout";
import Loading from "@components/Loading/Loading";
import ButtonCommon from "@components/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import SentInviteModal from "@components/SentInviteModal/SentInviteModal";
import { Input, Modal, Button, message } from "antd";

const { Search } = Input;

const ListUser = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const [isDLModalOpen, setIsDLModalOpen] = useState(false); // Ant Design modal state for delete
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const adminId = loginData ? loginData.adminId : null;
      const Token = loginData ? loginData.accessToken : null;

      try {
        const response = await fetch(
          "https://usfarmdata.org/fastapi/user_function/?operation=getuser",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Token}`,
            },
            body: JSON.stringify({
              adminid: adminId,
              status: "S",
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          console.log("Fetched data:", result);
          setData(result);
          setFilteredData(result); // Set initial filtered data
        } else {
          console.error("Failed to fetch data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    if (value) {
      const filtered = data.filter(
        (user) =>
          user.firstname.toLowerCase().includes(value.toLowerCase()) ||
          user.lastname.toLowerCase().includes(value.toLowerCase()) ||
          user.emailid.toLowerCase().includes(value.toLowerCase()) ||
          user.rolename.toLowerCase().includes(value.toLowerCase()) ||
          user.userid.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  };

  const columns = [
    {
      title: "User ID",
      dataIndex: "userid",
      key: "userid",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "firstname",
      key: "name",
      width: "20%",
      render: (text, record) => `${record.firstname} ${record.lastname}`,
    },
    {
      title: "Email",
      dataIndex: "emailid",
      key: "email",
      width: "20%",
      sorter: (a, b) => a.emailid.length - b.emailid.length,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: "20%",
    },
    {
      title: "User Role",
      dataIndex: "rolename",
      key: "role",
      width: "20%",
    },
    {
      title: "Last Signin",
      dataIndex: "lastlogindate",
      key: "lastsignin",
      type: "date",
      width: "20%",
      sorter: (a, b) => new Date(a.lastlogindate) - new Date(b.lastlogindate),
      render: (date) => new Date(date).toLocaleDateString(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Created Date",
      dataIndex: "createddate",
      key: "createddate",
      type: "createddate",
      width: "20%",
      sorter: (a, b) => new Date(a.createddate) - new Date(b.createddate),
      render: (date) => new Date(date).toLocaleDateString(),
      sortDirections: ["descend", "ascend"],
    },
  ];

  const handleOnEdit = () => {
    if (selectedRowKeys.length === 1) {
      const selectedUser = data.find(
        (user) => user.userid === selectedRowKeys[0]
      );
      navigate("/edituser", { state: { user: selectedUser } });
    } else if (selectedRowKeys.length > 1) {
      message.error("Please select only one user to edit.");
    } else {
      message.error("Please select a user to edit.");
    }
  };

  const handleGetCheckboxProps = (record) => {
    return {
      name: record.userid,
    };
  };

  const handleOnDelete = () => {
    if (selectedRowKeys.length > 0) {
      setIsDLModalOpen(true);
    } else {
      message.error("Please select at least one user to delete.");
    }
  };

  const confirmDelete = async () => {
    if (selectedRowKeys.length === 0) return;

    try {
      const token = localStorage.getItem("token");
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const adminId = loginData ? loginData.adminId : null;

      // Create an array of promises to delete each selected user
      const deletePromises = selectedRowKeys.map(async (userid) => {
        const response = await fetch(
          `https://usfarmdata.org/fastapi/user_function/?operation=deleteuser`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              user_id: userid,
              adminid: adminId,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to delete user: ${userid}`);
        }

        return userid; // Return the deleted user ID to filter out from the state
      });

      // Wait for all delete operations to finish
      await Promise.all(deletePromises);

      // Filter out the deleted users from both `data` and `filteredData`
      setData(data.filter((user) => !selectedRowKeys.includes(user.userid)));
      setFilteredData(
        filteredData.filter((user) => !selectedRowKeys.includes(user.userid))
      );

      message.success("Selected users deleted successfully.");
      setIsDLModalOpen(false);
      setSelectedRowKeys([]); // Clear selection after deletion
    } catch (error) {
      console.error("Error deleting users:", error);
      message.error("Failed to delete selected users.");
    }
  };

  const handleDLCancel = () => {
    setIsDLModalOpen(false);
  };

  const handleSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  const hasDeleteSelected = selectedRowKeys.length > 0;
  const hasUpdateSelected = selectedRowKeys.length === 1;

  return (
    <Container className="gap-4 bg-sky-50 items-center p-4" alignBox="column">
      <Box className="w-4/5 bg-white rounded-lg shadow-md p-4">
        <Container alignBox="row">
          <Box flexible>
            <Container>
              <div className="text-xl font-bold font-roboto text-blue-600">
                User Dashboard
              </div>
              <div className="text-sm text-gray-600">
                Manage your team and account permissions here
              </div>
              <div className="mt-5">
                <Search
                  placeholder="Search Members"
                  allowClear
                  enterButton="Search"
                  size="large"
                  style={{ width: 400 }}
                  onSearch={handleSearch} // Handle search
                />
              </div>
            </Container>
          </Box>
          <Box>
            <Container className="gap-1" alignBox="column">
              <span className="block text-xl font-bold leading-[26px] font-roboto text-gray-600">
                Organization
              </span>

              <span className="block text-xl font-bold leading-[26px] font-roboto text-blue-600">
                GoLeads
              </span>

              <ButtonCommon
                className="rounded-full mt-3"
                onClick={handleOpenModal}
              >
                <div className="flex flex-row items-center">
                  <AddIcon />
                  <span>Add Member</span>
                </div>
              </ButtonCommon>
            </Container>
          </Box>
        </Container>
      </Box>

      <Box className="bg-white w-4/5 rounded-lg shadow-md" flexible>
        {loading ? (
          <div className="h-full">
            <Loading />
          </div>
        ) : (
          <Container>
            <Box className="py-2 px-4">
              <Container alignBox="row" align="vertical" className="gap-3 p-2">
                <Box flexible className="text-lg font-semibold">
                  List of users
                </Box>
                {selectedRowKeys.length > 0 && (
                  <>
                    <Button
                      type="primary"
                      onClick={handleOnEdit}
                      // disabled={!hasUpdateSelected}
                    >
                      Edit
                    </Button>
                    <Button
                      type="primary"
                      danger
                      onClick={handleOnDelete}
                      disabled={!hasDeleteSelected}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </Container>
            </Box>
            <Box>
              <CustomTable
                columns={columns}
                data={filteredData} // Use filtered data
                //onEdit={handleOnEdit}
                //onDelete={handleOnDelete}
                rowKey="userid"
                onSelectChange={handleSelectChange}
                selectableRows={true}
                selectedRowKeys={selectedRowKeys}
                needActionColumn={false}
                heightOffset={410}
                getCheckboxProps={handleGetCheckboxProps}
              />
            </Box>
          </Container>
        )}
      </Box>

      {/* {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded-md shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p>Are you sure you want to delete {user.firstname}?</p>
            <div className="flex justify-end mt-4">
              <Button
                onClick={confirmDelete}
                className="bg-red-500 text-white mr-2"
              >
                Confirm
              </Button>
              <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            </div>
          </div>
        </div>
      )} */}
      <Modal
        title="Delete User"
        okText="Delete"
        open={isDLModalOpen}
        onOk={confirmDelete}
        onCancel={() => setIsDLModalOpen(false)}
        //confirmLoading={confirmLoading}
      >
        <p>Are you sure you want to delete this user?</p>
      </Modal>

      <SentInviteModal visible={isModalOpen} onClose={handleCloseModal} />
    </Container>
  );
};

export default ListUser;
