import React from "react";
import CustomModel from "@components/CustomModel/CustomModel";

const NoteModel = ({ title, open, onSave, onCancel, loading }) => {
  return (
    <CustomModel
      title={title}
      open={open}
      onSave={onSave}
      onCancel={onCancel}
      loading={loading}
      width={800}
    >
      <form>
        <div class="w-full mb-4 border border-gray-200 rounded-lg bg-gray-50 ">
          <div class="px-4 py-2 bg-white rounded-t-lg ">
            <label for="comment" class="sr-only">
              Your comment
            </label>
            <textarea
              id="comment"
              rows="6"
              class="w-full px-0 text-sm text-gray-900 bg-white border-0"
              placeholder="Write a note..."
              required
            ></textarea>
          </div>
          <div class="flex items-center justify-between px-3 py-2 border-t">
            <div>
              <div className="flex items-center gap-1">
                <div>Communication mode:</div>
                <div>
                  <div className="flex items-center gap-2">
                    <div>
                      <div class="flex items-center ">
                        <input
                          checked
                          id="default-radio-1"
                          type="radio"
                          value=""
                          name="default-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                          for="default-radio-1"
                          class="ms-2 text-sm font-medium text-gray-900"
                        >
                          Phone
                        </label>
                      </div>
                    </div>

                    <div>
                      <div class="flex items-center">
                        <input
                          id="default-radio-2"
                          type="radio"
                          value=""
                          name="default-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                          for="default-radio-2"
                          class="ms-2 text-sm font-medium text-gray-900 "
                        >
                          Email
                        </label>
                      </div>
                    </div>
                    <div>
                      <div class="flex items-center">
                        <input
                          id="default-radio"
                          type="radio"
                          value=""
                          name="default-radio"
                          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        />
                        <label
                          for="default-radio"
                          class="ms-2 text-sm font-medium text-gray-900 "
                        >
                          Other
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </CustomModel>
  );
};

export default NoteModel;
