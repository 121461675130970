import React, { useState } from "react";
import { Button, message, Tooltip } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const urlToken = localStorage.getItem("urlToken");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailid: "",
    phone: "",
    loginname: "",
    password: "",
    invitetoken: urlToken || "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = "First Name is required";
    if (!formData.lastname) newErrors.lastname = "Last Name is required";
    if (!formData.emailid) {
      newErrors.emailid = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailid)) {
      newErrors.emailid = "Email is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone Number should be 10 digits";
    }
    if (!formData.loginname) newErrors.loginname = "Login Name is required";
    if (!formData.password) newErrors.password = "Password is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    setLoading(true);

    const requestBody = { ...formData };
    console.log("requestBody", requestBody);

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/register_user/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        message.error({
          content: errorData.detail || "An error occurred. Please try again.",
          duration: 10,
        });
      } else {
        const responseData = await response.json();
        localStorage.setItem("logindata", JSON.stringify(responseData));
        message.success("You have been registered successfully.");
        setFormData({
          firstname: "",
          lastname: "",
          emailid: "",
          phone: "",
          loginname: "",
          password: "",
          invitetoken: "",
        });
        navigate("/settings/personal/profile");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleBackLogin = () => {
    if (urlToken) {
      navigate(`/login?token=${urlToken}`);
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="flex justify-center items-center h-full bg-gray-100">
      <form
        className="w-2/4 bg-white p-x py-12 rounded-xl relative"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
        onSubmit={handleSubmit}
      >
        <Tooltip title="Back To Login">
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            onClick={handleBackLogin}
            className="absolute left-3.5 top-3.5"
          />
        </Tooltip>

        <h2 className="text-2xl font-bold mb-7 text-center uppercase text-sky-700">
          Registration Form
        </h2>
        <div className="flex flex-row items-center justify-center flex-wrap w-full gap-3">
          {[
            "firstname",
            "lastname",
            "emailid",
            "phone",
            "loginname",
            "password",
          ].map((field, index) => (
            <div key={index} className="mb-3 relative w-80">
              <label className="block text-gray-700 mb-1 capitalize">
                {field.replace(/([A-Z])/g, " $1").trim()}
              </label>
              <input
                type={field === "password" ? "password" : "text"}
                name={field}
                className={`w-full p-2 border rounded-md ${
                  errors[field] ? "border-red-500" : "border-gray-300"
                }`}
                value={formData[field]}
                onChange={handleChange}
              />
              {errors[field] && (
                <p className="text-red-500 text-sm absolute top-full mt-1">
                  {errors[field]}
                </p>
              )}
            </div>
          ))}
        </div>
        <div className="mt-7">
          <div className="flex items-center justify-center">
            <Button
              className="w-96 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              {loading ? "Registering..." : "Register"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
