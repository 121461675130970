import React from "react";

const BookmarkButton = ({
  index,
  isActive,
  onClick,
  icon,
  text,
  defaultColor,
}) => {
  const activeStyles = {
    backgroundColor: isActive ? "rgb(59 130 246)" : "transparent",
    color: isActive ? "white" : defaultColor,
  };

  const textStyles = {
    color: isActive ? "white" : "black",
  };

  return (
    <li
      onClick={() => onClick(index)}
      style={{
        backgroundColor: isActive ? "rgb(59 130 246)" : "",
        color: isActive ? "white" : "black",
        padding: "0.3rem 0.8rem",
        borderRadius: "3rem",
        cursor: "pointer",
        transition: "all 0.5s",
        position: "relative",
      }}
    >
      <span
        style={{
          ...activeStyles,
          position: "absolute",
          inset: "0",
          borderRadius: "3rem",
          transition: "all 0.5s",
        }}
      ></span>
      <div
        className="flex flex-row items-center gap-1 relative z-10"
        style={{
          zIndex: 10,
          display: "flex",
          alignItems: "center",
          gap: "0.25rem",
        }}
      >
        <span style={{ color: isActive ? "white" : defaultColor }}>{icon}</span>
        <span style={textStyles} className="text-sm">
          {text}
        </span>
      </div>
    </li>
  );
};

export default BookmarkButton;
