import React, { useEffect, useState, useRef } from "react";
import { Container, Box } from "@lib/Layout";
import { Button, message } from "antd";
import useIsAdmin from "../../../hooks/useIsAdmin";
import _ from "lodash";

const Profile = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    emailid: "",
    phone: "",
    loginname: "",
    password: "",
    role: "",
  });

  const initialFormDataRef = useRef(formData);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfileData = async () => {
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const adminId = loginData ? loginData.adminId : null;
      const userId = loginData ? loginData.userId : null;
      const Token = loginData ? loginData.accessToken : null;

      if (adminId && userId) {
        try {
          const response = await fetch(
            "https://usfarmdata.org/fastapi/user_function/?operation=getprofile",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${Token}`,
              },
              body: JSON.stringify({
                adminid: adminId,
                userid: userId,
              }),
            }
          );

          const data = await response.json();
          console.log("Profile Data:", data);

          if (data && data.length > 0) {
            const userProfile = data[0];
            const profileData = {
              firstname: userProfile.firstname || "",
              lastname: userProfile.lastname || "",
              emailid: userProfile.emailid || "",
              phone: userProfile.phone || "",
              loginname: userProfile.loginname || "",
              password: "",
              role: userProfile.rolename || "",
            };
            setFormData(profileData);
            initialFormDataRef.current = profileData;
          }
        } catch (error) {
          console.error("Error fetching profile data:", error);
        }
      }
    };

    fetchProfileData();
  }, []);

  const validate = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = "First Name is required";
    if (!formData.lastname) newErrors.lastname = "Last Name is required";
    if (!formData.emailid) {
      newErrors.emailid = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.emailid)) {
      newErrors.emailid = "Email is invalid";
    }
    if (!formData.phone) {
      newErrors.phone = "Phone Number is required";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone Number should be 10 digits";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSubmit = _.debounce(async (e) => {
    e.preventDefault();
    if (!validate()) return;

    if (_.isEqual(initialFormDataRef.current, formData)) {
      message.info("No changes detected.");
      return;
    }

    setLoading(true);
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;
    const userId = loginData ? loginData.userId : null;
    const Token = loginData ? loginData.accessToken : null;

    try {
      const response = await fetch(
        "https://usfarmdata.org/fastapi/user_function/?operation=updateprofile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
          },
          body: JSON.stringify({
            adminid: adminId,
            userid: userId,
            ...formData,
          }),
        }
      );

      const result = await response.json();
      if (response.ok) {
        message.success("Profile updated successfully!");
        initialFormDataRef.current = formData; // Update initial form data after successful update
      } else {
        message.error(result.detail || "Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("An error occurred while updating the profile.");
    } finally {
      setLoading(false);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      password: "", // Clear password field only
    }));
  }, 300); // Debounce to prevent multiple clicks

  const isAdmin = useIsAdmin();
  const loginData = JSON.parse(localStorage.getItem("logindata"));
  const isGoogleLogin = loginData ? loginData.isemailverified : null;

  return (
    <Container>
      <Box className="p-3 border-b text-lg font-semibold">Edit Profile</Box>
      <Box flexible scroll="vertical">
        <div className="flex flex-wrap gap-3 p-5 w-9/12">
          {/* Input fields */}
          <div className="mb-3 relative w-80">
            <label className="block text-gray-700 mb-1">First Name</label>
            <input
              type="text"
              name="firstname"
              className={`w-full p-2 border rounded-md ${
                errors.firstname ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.firstname}
              onChange={handleChange}
            />
            {errors.firstname && (
              <p className="text-red-500 text-sm absolute top-full">
                {errors.firstname}
              </p>
            )}
          </div>
          <div className="mb-3 relative w-80">
            <label className="block text-gray-700 mb-1">Last Name</label>
            <input
              type="text"
              name="lastname"
              className={`w-full p-2 border rounded-md ${
                errors.lastname ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.lastname}
              onChange={handleChange}
            />
            {errors.lastname && (
              <p className="text-red-500 text-sm absolute top-full">
                {errors.lastname}
              </p>
            )}
          </div>
          <div className="mb-3 relative w-80">
            <label className="block text-gray-700 mb-1">Email</label>
            <input
              type="email"
              name="emailid"
              className={`w-full p-2 border rounded-md ${
                errors.emailid ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.emailid}
              onChange={handleChange}
            />
            {errors.emailid && (
              <p className="text-red-500 text-sm absolute top-full ">
                {errors.emailid}
              </p>
            )}
          </div>
          <div className="mb-3 relative w-80">
            <label className="block text-gray-700 mb-1">Phone</label>
            <input
              type="text"
              name="phone"
              className={`w-full p-2 border rounded-md ${
                errors.phone ? "border-red-500" : "border-gray-300"
              }`}
              value={formData.phone}
              onChange={handleChange}
            />
            {errors.phone && (
              <p className="text-red-500 text-sm absolute top-full ">
                {errors.phone}
              </p>
            )}
          </div>
          {!isGoogleLogin && (
            <>
              <div className="mb-3 relative w-80">
                <label className="block text-gray-700 mb-1">Login Name</label>
                <input
                  type="text"
                  name="loginname"
                  className={`w-full p-2 border rounded-md ${
                    errors.loginname ? "border-red-500" : "border-gray-300"
                  }`}
                  value={formData.loginname}
                  onChange={handleChange}
                />
                {errors.loginname && (
                  <p className="text-red-500 text-sm absolute top-full ">
                    {errors.loginname}
                  </p>
                )}
              </div>
              {/* <div className="mb-3 relative w-80">
                <label className="block text-gray-700 mb-1">Password</label>
                <input
                  type="password"
                  name="password"
                  className={`w-full p-2 border rounded-md ${
                    errors.password ? "border-red-500" : "border-gray-300"
                  }`}
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <p className="text-red-500 text-sm absolute top-full ">
                    {errors.password}
                  </p>
                )}
              </div> */}
            </>
          )}

          <div className="mb-3 relative w-80">
            <label className="block text-gray-700 mb-1">Role</label>
            <input
              type="text"
              name="role"
              className={`w-full p-2 border rounded-md  ${
                errors.role ? "border-red-500" : "border-gray-300"
              } ${!isAdmin && "cursor-not-allowed bg-gray-200"}`}
              value={formData.role}
              disabled={!isAdmin}
              onChange={handleChange}
            />
          </div>
        </div>
      </Box>
      <Box className="p-3 border-t">
        <Button type="primary" loading={loading} onClick={handleSubmit}>
          Update
        </Button>
      </Box>
    </Container>
  );
};

export default Profile;
