import React from "react";
import { Select } from "antd";

const { Option } = Select;

const DateFilterDropdown = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange} style={{ width: 200 }}>
      <Option value="today">Today</Option>
      <Option value="last7days">Last 7 Days</Option>
      <Option value="lastMonth">Last 30 Days</Option>
      <Option value="all">All Time</Option>
    </Select>
  );
};

export default DateFilterDropdown;
