import React, { useState } from "react";
import { useFontSize } from "../../context/FontSizeContext";
import { Tooltip } from "antd"; // Import Tooltip from Ant Design

const FontSizeDropdown = () => {
  const {
    fontSize,
    increaseFontSize,
    decreaseFontSize,
    resetFontSize,
    isDefaultSize,
  } = useFontSize();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const isMinSize = fontSize <= 1;
  const isMaxSize = fontSize >= 1.25;

  return (
    <div className="relative inline-block text-left">
      <div>
        <Tooltip title="Font Size Customization">
          <button
            onClick={toggleDropdown}
            className={`inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium ${
              isDropdownOpen
                ? "bg-blue-500 text-white ring-2 ring-blue-600"
                : "bg-white text-slate-700  hover:border-blue-500 "
            }`}
          >
            Font
            <svg
              className={`-mr-1 ml-2 h-5 w-5 transition-transform ${
                isDropdownOpen ? "rotate-180" : "rotate-0"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </Tooltip>
      </div>

      {isDropdownOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center gap-2">
              <div className="text-sm text-slate-600 font-semibold">
                Font Size:
              </div>

              {/* Tooltip for Decrease button */}
              <Tooltip title={isMinSize ? "Min size reached" : "Decrease"}>
                <button
                  onClick={decreaseFontSize}
                  className={`px-3 py-1 rounded-md ${
                    isMinSize
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-700 hover:bg-gray-400 active:bg-gray-500"
                  }`}
                  disabled={isMinSize}
                >
                  -
                </button>
              </Tooltip>

              {/* Tooltip for Increase button */}
              <Tooltip title={isMaxSize ? "Max size reached" : "Increase"}>
                <button
                  onClick={increaseFontSize}
                  className={`px-3 py-1 rounded-md ${
                    isMaxSize
                      ? "bg-gray-200 text-gray-400 cursor-not-allowed"
                      : "bg-gray-300 text-gray-700 hover:bg-gray-400 active:bg-gray-500"
                  }`}
                  disabled={isMaxSize}
                >
                  +
                </button>
              </Tooltip>

              {!isDefaultSize && (
                <span
                  onClick={resetFontSize}
                  className="text-sm text-blue-500 hover:text-blue-700 active:text-blue-800 rounded-md cursor-pointer"
                >
                  Reset
                </span>
              )}
            </div>

            <div>
              <span className="text-xs text-slate-600 font-semibold">
                <i className="text-xs font-semibold text-slate-400">
                  currentSize:
                </i>{" "}
                {fontSize} rem
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FontSizeDropdown;
