import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import useFetchGmailEmails from "../../hooks/useFetchGmailEmails";
import DateFilterDropdown from "@components/DateFilterDropdown/DateFilterDropdown";
import SkeletonLoader from "@components/SkeletonLoader/SkeletonLoader";
import { Input, Tooltip, message, Button, Empty } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import { InfoCircleOutlined } from "@ant-design/icons";
import EmptyLogo from "../../images/empty-new.jpg";
const { Search } = Input;

const TOKEN_EXPIRY_TIME = 24 * 60 * 60 * 1000;

const EmailActivities = () => {
  const [token, setToken] = useState(localStorage.getItem("g_token") || "");
  const [inboxDateFilter, setInboxDateFilter] = useState("today");
  const [inboxSearchQuery, setInboxSearchQuery] = useState("");

  const [sentDateFilter, setSentDateFilter] = useState("today");
  const [sentSearchQuery, setSentSearchQuery] = useState("");

  const isTokenExpired = () => {
    const expiryTime = localStorage.getItem("token_expiry_time");
    if (!expiryTime) return true;
    return new Date().getTime() > parseInt(expiryTime, 10);
  };

  const handleLogout = () => {
    localStorage.removeItem("g_token");
    localStorage.removeItem("token_expiry_time");
    setToken("");
    message.info("Session expired. Please log in again.");
  };

  const login = useGoogleLogin({
    onSuccess: (response) => {
      const accessToken = response.access_token;
      const expiryTime = new Date().getTime() + TOKEN_EXPIRY_TIME;

      setToken(accessToken);
      localStorage.setItem("g_token", accessToken);
      localStorage.setItem("token_expiry_time", expiryTime);

      message.success("Successfully logged in!");
    },
    onError: () => message.error("Login failed. Please try again."),
  });

  useEffect(() => {
    if (!token || isTokenExpired()) {
      handleLogout();
    }
  }, [token]);

  const {
    emails: inboxEmails,
    loading: loadingInbox,
    error: errorInbox,
  } = useFetchGmailEmails(
    token,
    "INBOX",
    100,
    inboxDateFilter,
    inboxSearchQuery
  );

  const {
    emails: sentEmails,
    loading: loadingSent,
    error: errorSent,
  } = useFetchGmailEmails(token, "SENT", 40, sentDateFilter, sentSearchQuery);

  // Handle search query
  const handleInboxSearch = (value) => {
    setInboxSearchQuery(value);
  };

  const handleSentSearch = (value) => {
    setSentSearchQuery(value);
  };

  useEffect(() => {
    if (errorInbox) {
      message.error(`Error fetching inbox emails: ${errorInbox}`);
    }
    if (errorSent) {
      message.error(`Error fetching sent emails: ${errorSent}`);
    }
  }, [errorInbox, errorSent]);

  useEffect(() => {
    if (loadingInbox && loadingSent) {
      message.loading("Please wait fetching your emails...", 0);
    } else if (!loadingInbox && !errorInbox) {
      message.destroy();
    }
  }, [loadingInbox, loadingSent, errorInbox]);

  return (
    <div className="h-full p-3 bg-sky-50">
      <Container className="gap-3">
        <Box
          className="bg-white rounded p-3 text-lg font-semibold"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          Gmail Activities
        </Box>
        {!token ? (
          <Box flexible>
            <div className="flex flex-col gap-2 justify-center items-center h-full bg-white">
              <div>
                <div className="flex items-center gap-1">
                  {" "}
                  <div className="text-lg text-yellow-500">
                    <InfoCircleOutlined />
                  </div>{" "}
                  <div className="text-2xl font-semibold text-slate-900">
                    Authorize
                  </div>
                </div>
              </div>

              <span className="text-center text-sm w-1/2 text-slate-500">
                To protect your data, once you{" "}
                <strong className="text-blue-500">
                  authorize access to your Google account or login via Continue
                  with Google,
                </strong>{" "}
                the session will remain active for 24 hours. After 24 hours,
                your session will automatically expire, and you'll need to
                reauthorize to continue accessing your email activities. This
                ensures that your account remains secure while using our
                services.
              </span>
              <Button type="primary" onClick={() => login()}>
                Authorize with Google account
              </Button>
            </div>
          </Box>
        ) : (
          <Box flexible className="">
            <Container alignBox="row" className="gap-3">
              {/* Incoming Emails */}
              <Box
                className="w-2/4 h-full rounded bg-white"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                }}
                scroll="vertical"
              >
                <Container>
                  <Box className="p-3 border-b">
                    <Container
                      alignBox="row"
                      align="vertical"
                      className="gap-10"
                    >
                      <div className="text-base font-semibold text-slate-700">
                        Incoming
                      </div>
                      <DateFilterDropdown
                        value={inboxDateFilter}
                        onChange={setInboxDateFilter}
                      />
                      <Search
                        placeholder="Search incoming emails"
                        allowClear
                        enterButton="Search"
                        onSearch={handleInboxSearch}
                        className="w-1/2"
                      />
                    </Container>
                  </Box>
                  <Box>
                    <li className="flex items-center gap-2 text-sm border-b p-3">
                      <p className="w-2/6 truncate">From address</p>
                      <p className="grow truncate w-2/5">Subject</p>
                      <p className="text-center w-1/5">Date</p>
                      <hr />
                    </li>
                  </Box>
                  <Box flexible scroll="vertical" className="p-3">
                    {loadingInbox ? (
                      <Container>
                        <SkeletonLoader />
                      </Container>
                    ) : inboxEmails.length === 0 ? (
                      <Container align="both">
                        <Empty
                          description={
                            errorInbox || "No incoming emails found."
                          }
                          image={EmptyLogo}
                          imageStyle={{
                            height: 250,
                          }}
                        />
                      </Container>
                    ) : (
                      <ul>
                        {inboxEmails.map((email, index) => (
                          <li
                            key={index}
                            className="flex items-center gap-2 text-sm border-b py-2"
                          >
                            <Tooltip title={email.to}>
                              <p className="w-2/6 truncate">{email.from}</p>
                            </Tooltip>

                            <Tooltip title={email.subject}>
                              <p className="grow truncate w-2/5">
                                {email.subject}
                              </p>
                            </Tooltip>

                            <p className="text-center  w-1/5">{email.date}</p>
                            <hr />
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </Container>
              </Box>

              {/* Outgoing Emails */}
              <Box
                className="w-2/4 h-full rounded bg-white"
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                }}
                scroll="vertical"
              >
                <Container>
                  <Box className="p-3 border-b">
                    <Container
                      alignBox="row"
                      align="vertical"
                      className="gap-10"
                    >
                      <div className="text-base font-semibold text-slate-700">
                        Outgoing
                      </div>
                      <DateFilterDropdown
                        value={sentDateFilter}
                        onChange={setSentDateFilter}
                      />
                      <Search
                        placeholder="Search outgoing emails"
                        allowClear
                        enterButton="Search"
                        onSearch={handleSentSearch}
                        className="w-1/2"
                      />
                    </Container>
                  </Box>
                  <Box>
                    <li className="flex items-center gap-2 text-sm border-b p-3">
                      <p className="w-2/6 truncate">To address</p>
                      <p className="grow truncate w-2/5">Subject</p>
                      <p className="text-center w-1/5">Date</p>
                      <hr />
                    </li>
                  </Box>
                  <Box flexible scroll="vertical" className="p-3">
                    {loadingSent ? (
                      <Container>
                        <SkeletonLoader />
                      </Container>
                    ) : sentEmails.length === 0 ? (
                      <Container align="both">
                        <Empty
                          description={
                            errorSent || " No outgoing emails found."
                          }
                          image={EmptyLogo}
                          imageStyle={{
                            height: 200,
                          }}
                        />
                      </Container>
                    ) : (
                      <ul>
                        {sentEmails.map((email, index) => (
                          <li
                            key={index}
                            className="flex items-center gap-2 text-sm border-b py-2"
                          >
                            <Tooltip title={email.to}>
                              <p className="w-2/6 truncate">{email.to}</p>
                            </Tooltip>

                            <Tooltip title={email.subject}>
                              <p className="grow truncate w-2/5">
                                {email.subject}
                              </p>
                            </Tooltip>

                            <p className="text-center  w-1/5">{email.date}</p>
                            <hr />
                          </li>
                        ))}
                      </ul>
                    )}
                  </Box>
                </Container>
              </Box>
            </Container>
          </Box>
        )}
      </Container>
    </div>
  );
};

export default EmailActivities;
