import { useState, useEffect } from "react";

const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const loginData = JSON.parse(localStorage.getItem("logindata"));

    if (loginData) {
      setIsAdmin(["Cheif Executive", "Admin"].includes(loginData.roleName));
    }
  }, []);

  return isAdmin;
};

export default useIsAdmin;
