import React from "react";

const SelectBoxField = ({
  value,
  onChange,
  placeholder,
  name,
  label,
  options,
}) => {
  return (
    <div className="relative z-0  mb-5 group min-w-48">
      <label
        htmlFor={name}
        className="block text-sm text-gray-500 dark:text-gray-400"
      >
        {label}
      </label>
      <div className="relative">
        <select
          name={name}
          id={name}
          className="block py-2 pr-8 pl-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          value={value}
          onChange={onChange}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <svg
          className=" text-slate-500 absolute right-0 top-0 bottom-0 m-auto"
          width="15"
          height="15"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M18 15l-6-6l-6 6h12" transform="rotate(180 12 12)" />
        </svg>
      </div>
    </div>
  );
};

export default SelectBoxField;
