import React, { useState, useEffect } from "react";

const DateTimeField = ({ value, onChange, placeholder, name, label }) => {
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10);
    setDefaultValue(formattedDate);
  }, []);

  return (
    <div className="relative z-0 mb-5 group min-w-48">
      <label
        htmlFor={name}
        className="block text-sm text-gray-500 dark:text-gray-400 mb-1"
      >
        {label}
      </label>
      <div className="relative">
        <input
          type="date"
          name={name}
          id={name}
          className="block  w-full text-sm text-gray-900 bg-transparent border-0 border-b border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder={placeholder}
          value={value || defaultValue}
          onChange={onChange}
          style={{ padding: "0.313rem 0" }}
        />
      </div>
    </div>
  );
};

export default DateTimeField;
