import React, { useState, useEffect } from "react";
import AddRoleModal from "../RoleModal/AddRoleModal";
import EditRoleModal from "../RoleModal/EditRoleModal";
import { Container, Box } from "@lib/Layout";
import Button from "@components/Button/Button";
import AddIcon from "@mui/icons-material/Add";
import { PlusOutlined } from "@ant-design/icons";
import { useRoles } from "../../../../context/RoleContext";
import CustomTable from "@components/CustomTable/CustomTable";
import CustomModel from "../../../../components/CustomModel/CustomModel";
import Loading from "@components/Loading/Loading";

function RolePage() {
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentRole, setCurrentRole] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [loading, setLoading] = useState(true);
  const { roles, setRoles } = useRoles();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      const loginData = JSON.parse(localStorage.getItem("logindata"));
      const adminId = loginData ? loginData.adminId : null;

      let userId = null;

      if (loginData) {
        userId = loginData.userId;
      }

      if (!userId) {
        console.error(
          "userId is null or undefined. Please ensure it's stored in logindata."
        );
        return;
      }

      try {
        const response = await fetch(
          "https://usfarmdata.org/fastapi/user_function/?operation=getrole",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              userid: userId,
              adminid: adminId,
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setRoles(result);
        } else {
          console.error("Failed to fetch roles, status:", response.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setRoles]);

  const columns = [
    {
      title: "Role ID",
      dataIndex: "roleid",
      key: "roleid",
      width: "10%",
    },
    {
      title: "Role Name",
      dataIndex: "rolename",
      key: "rolename",
      width: "10%",
    },
    // Add other columns as needed
  ];

  const handleAddRole = (roleData) => {
    const newRole = { ...roleData, id: roles.length + 1 };
    setRoles([...roles, newRole]);
    setModalOpen(false);
  };

  const handleEditRole = (roleData) => {
    setRoles(
      roles.map((role) =>
        role.id === currentRole.id ? { ...role, ...roleData } : role
      )
    );
    setModalOpen(false);
    setCurrentRole(null);
    setIsEditing(false);
  };

  const handleEdit = (role) => {
    setCurrentRole(role);
    setIsEditing(true);
    setModalOpen(true);
  };

  const handleDeleteClick = (role) => {
    setRoleToDelete(role);
    setShowDeleteModal(true);
  };
  const handleGetCheckboxProps = (record) => {
    return {
      name: record.roleid,
    };
  };

  const confirmDelete = async () => {
    const token = localStorage.getItem("token");
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;

    try {
      const response = await fetch(
        `https://usfarmdata.org/fastapi/user_function/?operation=deleterole`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            adminid: adminId,
            role_id: roleToDelete.roleid,
          }),
        }
      );

      if (response.ok) {
        setRoles(roles.filter((role) => role.roleid !== roleToDelete.roleid));
        console.log("Role deleted successfully");
      } else {
        console.error("Failed to delete role, status:", response.status);
      }
    } catch (error) {
      console.error("Error deleting role:", error);
    } finally {
      setShowDeleteModal(false);
      setRoleToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRoleToDelete(null);
  };
  const handleSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  return (
    <Container >
      {loading ? (
        <div className="h-full">
          <Loading />
        </div>
      ) : (
        <>
          <Box className="border-b py-2 px-4">
            <div className="flex items-center">
              <div className="grow text-lg font-semibold">Roles</div>
              <div>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setModalOpen(true);
                    setIsEditing(false);
                    setCurrentRole(null);
                  }}
                >
                  <AddIcon />
                  Add Roles
                </Button>
              </div>
            </div>
          </Box>

          <Box className="p-2">
            <CustomTable
              columns={columns}
              data={roles}
              onEdit={handleEdit}
              onDelete={handleDeleteClick}
              getCheckboxProps={handleGetCheckboxProps}
              rowKey="roleid"
              onSelectChange={handleSelectChange}
              selectableRows={true}
              selectedRowKeys={selectedRowKeys}
              needActionColumn={true}
              heightOffset={400}
            />
          </Box>
        </>
      )}
      
      {modalOpen && !isEditing && (
        <AddRoleModal
          visible={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAddRole}
        />
      )}

      {modalOpen && isEditing && (
        <EditRoleModal
          visible={modalOpen}
          role={currentRole}
          onClose={() => setModalOpen(false)}
          onSave={handleEditRole}
        />
      )}

      {showDeleteModal && roleToDelete && (
        <CustomModel
          title="Delete Role"
          open={showDeleteModal}
          onSave={confirmDelete}
          onCancel={handleDeleteCancel}
          saveButtontext="Delete"
          
        >
          <p>Are you sure you want to delete this role?</p>
        </CustomModel>
      )}
    </Container>
  );
}

export default RolePage;
