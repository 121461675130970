import React, { useState, useEffect } from "react";
import { Container, Box } from "@lib/Layout";
import { useNavigate } from "react-router-dom";
import CustomTable from "@components/CustomTable/CustomTable";
import ContactHeader from "../ContactHeader/ContactHeader";
import ContactSubHeader from "../ContactSubHeader/ContactSubHeader";
import RecentHistory from "../RecentHistory/RecentHistory";
import FieldContainer from "../FieldContainer/FieldContainer";
// import BusinessPage from "../../BusinessPage/BusinessPage";
// import ConsumerPage from "../../ConsumerPage/ConsumerPage";
// import FarmPage from "../../FarmPage/FarmPage";
// import ProfilePage from "../../ProfilePage/ProfilePage";
// import Propertypage from "../../Propertypage/Propertypage";
import { useWindowWidth } from "../../../context/WindowContext";
import NoteModel from "../NoteModel/NoteModel";
import Button from "../../../components/Button/Button";
import useFetchListData from "../../../hooks/useFetchListData";
import useConactBoxDragAndDrop from "../../../hooks/useConactBoxDragAndDrop";
import columns from "./contactColumns";
import { preprocessData, Loading, Error } from "./contactManagementUtils";
import TextBoxField from "@components/TextBoxField/TextBoxField";
import SelectBoxField from "@components/SelectBoxField/SelectBoxField";
import DateTimeField from "@components/DateTimeField/DateTimeField";

const BusinessPage = ({ currentDataItem }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField label="Company" value={currentDataItem.company || ""} />
      </div>
      <div>
        <TextBoxField label="SIC Code" value={currentDataItem.sicCode || ""} />
      </div>
      <div>
        <TextBoxField
          label="Industry Keyword"
          value={currentDataItem.industryKeyword || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Employee Size"
          value={currentDataItem.employeeSize || ""}
        />
      </div>
      <div>
        <TextBoxField label="Revenue" value={currentDataItem.revenue || ""} />
      </div>
      <div>
        <TextBoxField label="Website" value={currentDataItem.website || ""} />
      </div>
      <div>
        <TextBoxField
          label="LinkedIn Profile"
          value={currentDataItem.linkedinProfile || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Small Business"
          value={currentDataItem.smallBusiness || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Year Started"
          value={currentDataItem.yearStarted || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Business Status"
          value={currentDataItem.businessStatus || ""}
        />
      </div>
    </Container>
  );
};

const ConsumerPage = () => {
  const [selectedDateTime, setSelectedDateTime] = useState("");

  const handleChange = (event) => {
    setSelectedDateTime(event.target.value);
  };
  const options = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
  ];
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField label="Age" />
      </div>
      <div>
        <SelectBoxField
          label="Gender"
          placeholder="Select an option"
          name="selectBox"
          options={options}
        />
      </div>
      <div>
        <DateTimeField
          value={selectedDateTime}
          onChange={handleChange}
          placeholder="Select date and time"
          name="dateTime"
          label="Date of Birth"
        />
      </div>
      <div>
        <TextBoxField label="Maritial Status" />
      </div>
      <div>
        <TextBoxField label="Dwelling Type" />
      </div>
      <div>
        <TextBoxField label="Estimated household income" />
      </div>
      <div>
        <TextBoxField label="Home Market Value" />
      </div>
      <div>
        <TextBoxField label="Home owner" />
      </div>
      <div>
        <TextBoxField label="Real estate investor" />
      </div>
      <div>
        <TextBoxField label="Have children" />
      </div>
      <div>
        <TextBoxField label="Education" />
      </div>
      <div>
        <TextBoxField label="Occupation" />
      </div>
    </Container>
  );
};

const FarmPage = () => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField label="Record ID" />
      </div>
      <div>
        <TextBoxField label="Crop" />
      </div>
      <div>
        <TextBoxField label="Livestock" />
      </div>
      <div>
        <TextBoxField label="Livestock Total Head" />
      </div>
      <div>
        <TextBoxField label="Totla Acres" />
      </div>
      <div>
        <TextBoxField label="Exact Acres" />
      </div>
      <div>
        <TextBoxField label="Estimated Revenue" />
      </div>
      <div>
        <TextBoxField label="Producer Type" />
      </div>
      <div>
        <TextBoxField label="Rural" />
      </div>
      <div>
        <TextBoxField label="SIC Code" />
      </div>
      <div>
        <TextBoxField label="Entity Type" />
      </div>
    </Container>
  );
};
const ProfilePage = ({ currentDataItem }) => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField
          label="Name"
          name="name"
          value={currentDataItem.name || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Title"
          name="title"
          value={currentDataItem.title || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Phone"
          name="phone"
          value={currentDataItem.phone || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Email"
          name="email"
          value={currentDataItem.email || ""}
        />
      </div>
      <div>
        <TextBoxField
          label="Address"
          name="address"
          value={currentDataItem.address || ""}
        />
      </div>
    </Container>
  );
};

const Propertypage = () => {
  return (
    <Container alignBox="row" wrap="wrap" className="gap-y-3 gap-x-8">
      <div>
        <TextBoxField label="Clip ID" />
      </div>
      <div>
        <TextBoxField label="Property APN" />
      </div>
      <div>
        <TextBoxField label="Latitude" />
      </div>
      <div>
        <TextBoxField label="Longitude" />
      </div>
      <div>
        <TextBoxField label="Situs Address" />
      </div>
      <div>
        <TextBoxField label="Owner Name" />
      </div>
      <div>
        <TextBoxField label="Owner Name" />
      </div>
      <div>
        <TextBoxField label="Land Square Footage" />
      </div>
      <div>
        <TextBoxField label="Land Use Code" />
      </div>
      <div>
        <TextBoxField label="Mailing Address" />
      </div>
    </Container>
  );
};
const ContactMangementHome = () => {
  const [isGridView, setIsGridView] = useState(true);
  const windowWidth = useWindowWidth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [saveloading, setLoading] = useState(false);
  const { data, error, loading } = useFetchListData();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  console.log("Fetched data:", data);
  const processedData = preprocessData(data || []);
  const currentDataItem = data && data.length > 0 ? data[currentIndex] : {};
  console.log("Current Data Item:", currentDataItem);
  const totalCount = data ? data.length : 0;

  const [list, setList] = useState([
    {
      id: "profile",
      title: "Profile Information",
      component: <ProfilePage currentDataItem={currentDataItem} />,
    },
    {
      id: "business",
      title: "Business Information",
      component: <BusinessPage currentDataItem={currentDataItem} />,
    },
    {
      id: "property",
      title: "Property Information",
      component: <Propertypage />,
    },
    { id: "farm", title: "Farm Information", component: <FarmPage /> },
    {
      id: "consumer",
      title: "Consumer Information",
      component: <ConsumerPage />,
    },
  ]);

  useEffect(() => {
    setList((prevList) =>
      prevList.map((item) =>
        item.id === "profile"
          ? {
              ...item,
              component: <ProfilePage currentDataItem={currentDataItem} />,
            }
          : item.id === "business"
          ? {
              ...item,
              component: <BusinessPage currentDataItem={currentDataItem} />,
            }
          : item
      )
    );
  }, [currentDataItem]);

  //other funtions
  useEffect(() => {
    const savedListOrder = localStorage.getItem("draggedListOrder");
    if (savedListOrder) {
      const orderedIds = JSON.parse(savedListOrder);
      const orderedList = orderedIds.map((id) =>
        list.find((item) => item.id === id)
      );
      setList(orderedList);
    }
  }, []);

  const {
    handleDragStart,
    handleDragEnter,
    handleDragEnd,
    handleDragOver,
    containerRef,
  } = useConactBoxDragAndDrop(list, setList);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleGetCheckboxProps = (record) => {
    return {
      contactid: record.contactid,
    };
  };

  const handleSave = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setIsModalVisible(false);
    }, 2000);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilter = () => {
    navigate("/filter");
  };

  const handleViewToggle = (isGrid) => {
    const view = isGrid ? "grid" : "list";
    localStorage.setItem("view", view);
    setIsGridView(isGrid);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const storedContactId = localStorage.getItem("contactId");
    console.log("Stored contactId:", storedContactId);

    if (data && Array.isArray(data) && data.length > 0) {
      const index = data.findIndex(
        (item) =>
          item.contactid && item.contactid.toString() === storedContactId
      );
      if (index !== -1) {
        console.log("Found index:", index);
        setCurrentIndex(index);
      } else {
        console.log("ContactId not found, setting index to 0");
        setCurrentIndex(0);
      }
    } else {
      console.log("No data available or data is not an array");
    }
  }, [data]);

  const handleEdit = (record) => {
    console.log("Edit", record);
    localStorage.setItem("contactId", record.contactid);
    handleViewToggle(true);
    window.location.reload();
  };

  const handleDelete = (record) => {
    console.log("Delete", record);
  };

  const handleSelectChange = (selectedKeys) => {
    console.log(selectedKeys);
    setSelectedRowKeys(selectedKeys);
  };

  const handleNext = () => {
    if (data && currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (data && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Container
      className="py-3 px-4 gap-8"
      alignBox="column"
      style={{ backgroundColor: isGridView ? "#fff" : "#F3F7F9" }}
    >
      <Box>
        <ContactHeader
          handleFilter={handleFilter}
          onToggle={handleViewToggle}
          nextClick={handleNext}
          prewClick={handlePrevious}
          currentIndex={currentIndex + 1}
          totalCount={totalCount}
        />
      </Box>
      {isGridView && (
        <Box>
          <ContactSubHeader
            title={currentDataItem.name || "James Pickard"}
            contactId={currentDataItem.contactid}
            handleNote={showModal}
            companyName={currentDataItem.company || "Not Available"}
            Phone={currentDataItem.phone || "Not Available"}
            Email={currentDataItem.email || "Not Available"}
            isloading={loading}
          />
        </Box>
      )}
      <Box flexible>
        {isGridView ? (
          <Container
            alignBox={windowWidth > 600 ? "row" : "column"}
            className="gap-4"
          >
            <Box flexible={windowWidth > 600 ? true : false}>
              <div
                className="h-full overflow-auto scroll-smooth"
                ref={containerRef}
                onDragOver={handleDragOver}
              >
                <div className="flex flex-col gap-5 px-2">
                  {list.map((item, index) => (
                    <FieldContainer
                      key={item.id}
                      title={item.title}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragEnter={(e) => handleDragEnter(e, index)}
                      onDragEnd={handleDragEnd}
                    >
                      {item.component}
                    </FieldContainer>
                  ))}
                </div>
              </div>
            </Box>
            <Box>
              <RecentHistory title="Social Media Profile">
                <div className="h-full flex justify-center items-center">
                  <iframe
                    src="https://www.linkedin.com/in/karthickayyankalai/"
                    title="LinkedIn Profile"
                    // width="600"
                    // height="400"
                    frameBorder="0"
                  />
                </div>
              </RecentHistory>
            </Box>
          </Container>
        ) : (
          <Container
            alignBox="column"
            style={{ boxShadow: "0px 1px 10px #0000000F" }}
            className="bg-white rounded"
          >
            <Box className="border-b">
              <Container alignBox="row" align="vertical" className="px-3 py-2">
                <Box
                  flexible
                  className="font-roboto font-semibold text-lg"
                  style={{ color: "rgb(58, 58, 58)" }}
                >
                  Contacts
                </Box>
                <div>
                  <Button>Save as List</Button>
                </div>
              </Container>
            </Box>
            <Box className="p-2" flexible>
              {loading ? (
                <Loading />
              ) : error ? (
                <Error />
              ) : (
                <CustomTable
                  columns={columns}
                  data={processedData}
                  rowKey="contactid"
                  onEdit={handleEdit}
                  onSelectChange={handleSelectChange}
                  onDelete={handleDelete}
                  getCheckboxProps={handleGetCheckboxProps}
                  selectableRows={true}
                  selectedRowKeys={selectedRowKeys}
                  EmptyMessage={() => <div>No Data Available</div>}
                />
              )}
            </Box>
          </Container>
        )}
      </Box>
      <NoteModel
        title="Add Note"
        open={isModalVisible}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </Container>
  );
};

export default ContactMangementHome;
