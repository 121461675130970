import React, { useEffect, useState } from "react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import Tooltip from "@mui/material/Tooltip";
const ViewToggleButton = ({ onToggle }) => {
  const [isGridView, setIsGridView] = useState(true);

  useEffect(() => {
    const storedView = localStorage.getItem("view");
    if (storedView) {
      setIsGridView(storedView === "grid");
      onToggle(storedView === "grid");
    }
  }, [onToggle]);

  const handleToggle = (view) => {
    setIsGridView(view === "grid");
    localStorage.setItem("view", view);
    onToggle(view === "grid");
  };

  return (
    <div className="flex flex-row items-center border border-gray-300 rounded">
      <button
        className={`px-2 py-1  ${
          isGridView ? "bg-blue-500 text-white rounded" : "text-gray-500"
        }`}
        onClick={() => handleToggle("grid")}
      >
        <Tooltip title="Grid View">
          <RecentActorsIcon />
        </Tooltip>
      </button>
      <button
        className={`px-2 py-1  ${
          !isGridView ? "bg-blue-500 text-white rounded" : "text-gray-500"
        }`}
        onClick={() => handleToggle("list")}
      >
        <Tooltip title="List View">
          <FormatListBulletedIcon />
        </Tooltip>
      </button>
    </div>
  );
};

export default ViewToggleButton;
