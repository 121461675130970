// RolesContext.js

import React, { createContext, useState, useContext, useEffect } from 'react';

const RolesContext = createContext();

export const useRoles = () => useContext(RolesContext);

export const RolesProvider = ({ children }) => {
    // Initialize roles from local storage if available
    const [roles, setRoles] = useState(() => {
        const localData = localStorage.getItem('roles');
        return localData ? JSON.parse(localData) : [];
    });

    // Update local storage whenever roles change
    useEffect(() => {
        localStorage.setItem('roles', JSON.stringify(roles));
    }, [roles]);

    return (
        <RolesContext.Provider value={{ roles, setRoles }}>
            {children}
        </RolesContext.Provider>
    );
};
