import React from "react";
import { Container, Box } from "@lib/Layout";
const RecentHistory = ({ title, children }) => {
  return (
    <Container
      alignBox="column"
      className="min-w-96 rounded-md"
      style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
    >
      <Box className="border-b border-gray-300 px-4 py-2 text-blue-500">
        {title}
      </Box>
      <Box flexible scroll="vertical">
        {children}
      </Box>
    </Container>
  );
};

export default RecentHistory;
