import React from "react";

const Title = ({ tagName: Tag, className, children, style }) => {
  return (
    <Tag className={className} style={style}>
      {children}
    </Tag>
  );
};

export default Title;
