import React, { useState, useEffect } from "react";
import { Input, Select } from "antd";
import CustomModel from "../../../../components/CustomModel/CustomModel";

function EditRoleModal({ visible,role, onClose, onSave }) {
  const [name, setName] = useState("");
  const [level, setLevel] = useState("Level 1");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Active");

  useEffect(() => {
    if (role) {
      console.log("Role prop received:", role);
      setName(role.rolename);
      setLevel(role.level);
      setDescription(role.roledescription || "");
      setStatus(role.status || "Active");
    }
  }, [role]);

  const handleSave = async () => {
    const token = localStorage.getItem("token");
    const loginData = JSON.parse(localStorage.getItem("logindata"));
    const adminId = loginData ? loginData.adminId : null;

    const roleLevel = level ? parseInt(level.split(" ")[1]) : 1;

    const requestBody = {
      role_id: role.roleid,
      rolename: name,
      rolelevel: roleLevel,
      roledescription: description,
      adminid: adminId,
    };
    console.log(requestBody);

    const url = "https://usfarmdata.org/fastapi/user_function/?operation=editrole";
    const method = "POST";
    setLoading(true);

    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Failed to save role:", errorData);
        alert(`Failed to save role: ${response.statusText}`);
        return;
      }

      const responseData = await response.json();
      onSave(responseData);
      onClose();
    } catch (error) {
      console.error("Error during save operation:", error);
      alert("Failed to save role due to a network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModel
      title="Edit Role"
      open={visible}
      onCancel={onClose}
      loading={loading}
      onSave={handleSave}
      
    >
      <div className="flex flex-col space-y-4">
        <input
          className="border border-gray-300 p-2 w-full"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Type Role Name"
        />
        <select
          className="border border-gray-300 p-2 w-full"
          value={level}
          onChange={(e) => setLevel(e.target.value)}
        >
          <option value="Level 1">Level 1</option>
          <option value="Level 2">Level 2</option>
          <option value="Level 3">Level 3</option>
        </select>
        <input
          className="border border-gray-300 p-2 w-full"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Role Description"
        />
        <select
          className="border border-gray-300 p-2 w-full"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
        >
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
        </select>
      </div>
    </CustomModel>
  );
}

export default EditRoleModal;
