import React from "react";
import { Modal, Button } from "antd";

const CustomModel = ({
  title,
  open,
  onSave,
  onCancel,
  loading,
  children,
  width,
  saveButtontext = "Save",
}) => {
  return (
    <Modal
      title={title}
      open={open}
      width={width}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={onSave}>
          {saveButtontext}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default CustomModel;
