import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LockIcon from "@mui/icons-material/Lock";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Container, Box } from "@lib/Layout";
import useIsAdmin from "../../../hooks/useIsAdmin";

import styled from "@emotion/styled";

const PersonIcon = styled(PersonOutlineIcon)`
  font-size: 1.3rem;
  color: rgb(59 130 246);
`;
const Lock = styled(LockIcon)`
  font-size: 1.2rem;
  color: rgb(59 130 246);
`;

const Team = styled(GroupsIcon)`
  font-size: 1.3rem;
  color: rgb(59 130 246);
`;
const Roles = styled(ManageAccountsIcon)`
  font-size: 1.3rem;
  color: rgb(59 130 246);
`;
const Bookmark = styled(BookmarkBorderIcon)`
  font-size: 1.4rem;
  color: rgb(59 130 246);
`;

const SettingsRoute = () => {
  const isAdmin = useIsAdmin();
  return (
    <Container
      alignBox="column"
      className="gap-4 justify-center items-center bg-sky-50 py-4"
    >
      <Box className="w-11/12 text-xl font-semibold">Account Settings</Box>
      <Box flexible className="w-11/12">
        <Container alignBox="row" className="gap-4">
          <Box
            className="bg-sky-50 min-w-56 max-w-56"
            flexible
            scroll="vertical"
          >
            <div className="flex flex-col gap-4 ">
              <div
                className="bg-white rounded-md "
                style={{
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                }}
              >
                <div className="p-2 border-b">Personal</div>
                <div className="p-2 flex flex-col gap-1">
                  <NavLink
                    to="personal/profile"
                    className={({ isActive }) =>
                      isActive
                        ? "p-2 rounded-md text-blue-500 bg-blue-100 text-sm"
                        : "p-2 rounded-md text-gray-700 hover:bg-gray-100 text-sm "
                    }
                  >
                    <div className="flex items-center gap-2">
                      {" "}
                      <span className="leading-none">
                        <PersonIcon />
                      </span>
                      <span>Profile</span>
                    </div>
                  </NavLink>

                  <NavLink
                    to="personal/password"
                    className={({ isActive }) =>
                      isActive
                        ? "p-2 rounded-md text-blue-500 bg-blue-100 text-sm"
                        : "p-2 rounded-md text-gray-700 hover:bg-gray-100 text-sm"
                    }
                  >
                    <div className="flex items-center gap-2">
                      <span className="leading-none">
                        <Lock />
                      </span>
                      <span>Password</span>
                    </div>
                  </NavLink>
                </div>
              </div>

              {isAdmin && (
                <div
                  className="bg-white  rounded-md"
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                  }}
                >
                  <div className="p-2 border-b">Company</div>
                  <div className="p-2 flex flex-col gap-1">
                    <NavLink
                      to="company/team-members"
                      className={({ isActive }) =>
                        isActive
                          ? "p-2 rounded-md text-blue-500 bg-blue-100 text-sm"
                          : "p-2 rounded-md text-gray-700 hover:bg-gray-100 text-sm"
                      }
                    >
                      <div className="flex items-center gap-2">
                        <span className="leading-none">
                          <Team />
                        </span>
                        <span>Team Members</span>
                      </div>
                    </NavLink>
                    <NavLink
                      to="company/roles"
                      className={({ isActive }) =>
                        isActive
                          ? "p-2 rounded-md text-blue-500 bg-blue-100 text-sm"
                          : "p-2 rounded-md text-gray-700 hover:bg-gray-100 text-sm"
                      }
                    >
                      <div className="flex items-center gap-2">
                        <span className="leading-none">
                          <Roles />
                        </span>
                        <span>Roles</span>
                      </div>
                    </NavLink>
                    <NavLink
                      to="company/bookmark"
                      className={({ isActive }) =>
                        isActive
                          ? "p-2 rounded-md text-blue-500 bg-blue-100 text-sm"
                          : "p-2 rounded-md text-gray-700 hover:bg-gray-100 text-sm"
                      }
                    >
                      <div className="flex items-center gap-2">
                        <span className="leading-none">
                          <Bookmark />
                        </span>
                        <span>Bookmark</span>
                      </div>
                    </NavLink>
                  </div>
                </div>
              )}
            </div>
          </Box>
          <Box
            flexible
            className="bg-white rounded-md "
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            }}
          >
            <Outlet />
          </Box>
        </Container>
      </Box>
    </Container>
  );
};

export default SettingsRoute;
